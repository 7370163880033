<template>
    <div class="CategoryCreate" style="background-color: #f2f2f2; margin-top: 8px">
        <el-card shadow="never">
            <el-page-header @back="goBack" />
        </el-card>
        <el-card shadow="never">
            <el-button type="primary" @click="handleSave" size="small">保存</el-button>
            <el-button type="primary" @click="handleAdd" size="small">添加</el-button>
        </el-card>
        <el-card shadow="never" style="margin-top: 8px" align="left">
            <el-table
                id="printMe"
                border
                stripe
                style="width: 100%"
                :data="tableData"
                size="mini"
                :highlight-current-row="true"
                max-height="440"
            >
                <el-table-column label="序号" type="index" width="80" align="center" />
                <el-table-column label="名称" width="160">
                    <template slot-scope="scope">
                        <el-input v-model="scope.row.names" size="mini" />
                    </template>
                </el-table-column>
                <el-table-column label="上级类目" width="160">
                    <template slot-scope="scope">
                        <el-select
                            placeholder="请选择类目"
                            v-model="scope.row.parentCodes"
                            size="mini"
                            @change="onSelectCategory"
                            filterable
                        >
                            <el-option label="请选择" value="" />
                            <el-option
                                v-for="group in meta.categories"
                                :label="group.name"
                                :value="group.code"
                                :key="group.code"
                            />
                        </el-select>
                    </template>
                </el-table-column>
                <el-table-column label="等级" width="160">
                    <template slot-scope="scope">
                        <el-input v-model.number="scope.row.levels" size="mini" readonly />
                    </template>
                </el-table-column>
                <el-table-column label="显示顺序" width="160">
                    <template slot-scope="scope">
                        <el-input-number
                            v-model="scope.row.showIndexs"
                            size="mini"
                            :precision="0"
                            controls-position="right"
                            :min="1"
                            :max="100"
                        />
                    </template>
                </el-table-column>
                <el-table-column label="操作" width="180">
                    <template slot-scope="scope">
                        <el-button size="mini" type="danger" @click="handleDelete(scope.$index)">删除</el-button>
                    </template>
                </el-table-column>
            </el-table>
        </el-card>
    </div>
</template>

<script>
import UrlUtils from 'js/UrlUtils';
import Util from 'js/Util';

export default {
    name: 'CategoryCreate',
    data() {
        return {
            meta: {
                categories: [],
            },
            tableData: [],
            url: {
                save: '/goods/category/createExtend',
            },
        };
    },
    mounted() {
        const _this = this;
        UrlUtils.GroupedGoodsCategories(_this, (rst) => {
            _this.meta.categories = rst;
        });
    },
    methods: {
        handleSave() {
            if (!this.tableData.length) {
                this.$message.error('无数据');
                return false;
            }
            for (const e of this.tableData) {
                if (Util.isEmpty(e.names)) {
                    this.$message.error('请填写类目名称');
                    return;
                }
                if (Util.isEmpty(e.levels)) {
                    this.$message.error('请选择等级');
                    return;
                }
                if (Util.isEmpty(e.showIndexs)) {
                    this.$message.error('请填写显示顺序');
                    return;
                }
            }
            const _params = {
                names: this.tableData.map((r) => r.names),
                parentCodes: this.tableData.map((r) => r.parentCodes || ' '),
                levels: this.tableData.map((r) => r.levels),
                showIndexs: this.tableData.map((R) => R.showIndexs || 1),
            };
            const arr = new Set(_params.names);
            if (arr.size != _params.names.length) {
                this.$message.error('姓名不能重复');
                return;
            }
            UrlUtils.PostRemote(this, this.url.save, _params, null, (res) => {
                this.$message.success('创建成功');
                this.goBackAndReload();
            });
        },
        handleAdd() {
            this.tableData = this.tableData.concat({
                names: '',
                parentCodes: '',
                levels: 1,
                showIndexs: '1',
            });
        },
        handleDelete(index) {
            const _a = this.tableData;
            _a.splice(index, 1);
            this.tableData = _a;
        },
        onSelectCategory() {
            this.tableData.forEach((t) => {
                if (t.parentCodes != '') {
                    t.levels = 2;
                } else {
                    t.levels = 1;
                }
            });
        },
    },
};
</script>

<style scoped>
.BatchCreate .el-form-item {
    margin-bottom: 0;
}

.BatchCreate .el-table th.gutter {
    display: table-cell;
}
</style>
